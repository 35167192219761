import Header from "../components/header";
import Footer from "../components/footer";

function Policy() {
    return ( 
        <>
            <Header />
            <main>
                <div className="container">
                <br /><br />

                Privacy policy
                <br /><br />
                We believe it is important to handle the personal data entrusted to us in a transparent and responsible manner. We therefore process your personal data in a careful manner and in accordance with the General Data Protection Regulation ('GDPR') and the associated implementing law. We advise you to read the Privacy Policy carefully and to keep a copy of it for your own administration.
                <br /><br />
                WHAT DATA DO WE PROCESS?
                <br /><br />

                We process (personal) data via the Website. For example, if you visit our Website, create an Account, create an Advertisement or use the messaging function on the Website. We process the following personal data:

                <br /><br />
                a. Account
                <br /><br />

                - e-mail address - IP address - billing address
                (this is optional)

                <br /><br />
                b. Advertisement

                <br /><br />
                In addition to the data from the Account, the following personal data may be processed for placing an Advertisement (including Profile):
                - work name
                - gender
                - date
                of birth - postal code (can only be used with 4 digits)
                - telephone number
                - visual and film material

                <br /><br />
                c. Messages
                <br /><br />

                If you use the possibility to contact an Advertiser via the messaging function on the Website, the content of the message will be visible to the Advertiser with whom you are communicating.
                <br /><br />

                d. Search behavior and usage data
                <br /><br />

                On the Website we also collect data relating to your (search) behavior on and use of the Website and the information offered on it. For example, we may use cookies, IP addresses, device and browser information and location information.

                <br /><br />
                e. E-mail newsletter

                <br /><br />
                If you sign up for our e-mail newsletter, we will process your e-mail address. You can always unsubscribe from the newsletter by clicking on the link 'unsubscribe from the newsletter'. This link is located at the bottom of every newsletter.
                <br /><br />
                PROVISION OF PERSONAL DATA TO THIRD PARTIES

                <br /><br />

                We do not provide the personal data to third parties, unless we are obliged to do so on the basis of a legal obligation.

                <br /><br />

                Processors

                <br /><br />

                For the management of the Advertisements placed on the Website, for the processing of payment or for other services related to our Website or Services, we may use other service providers. For example, the administrators or software developers or payment service providers engaged by us. These suppliers (so-called 'processors') process the personal data exclusively on our behalf. We have concluded a processing agreement with the processors in which the processor is obliged to comply with all obligations under the GDPR and this Privacy Policy.
                <br /><br />
                SECURITY MEASURES

                <br /><br />

                We have taken appropriate technical and organizational measures to protect your personal data. This protects your personal data against loss or any form of unlawful or unauthorized processing and against accidental loss, destruction or damage. These security measures include the use of secure socket layer (SSL) / Transport layer security (TLS) encryption or other forms of encryption, Encrypted passwords – servers protected by advanced firewall.



                <br /><br /><br />
                </div>
            </main>
            <Footer />
        </>
     );
}

export default Policy;
import Header from "../components/header";
import Footer from "../components/footer";

function Complaints() {
    return ( 
        <>
            <Header />
            <main>
                <div className="container">
                <br /><br />

                Complaints policy

                <br /><br />
                We always endeavor to treat our clients fairly and deliver an excellent level of service, but if for any reason you are unhappy with the service we have provided, we would encourage you to discuss your concerns with our dedicated complaints handling team. We will do our best to fix things quickly and fairly.
                <br /><br />
                If you have a complaint about 4PlayDate.com (including any complaint about Content appearing on 4PlayDate.com or the conduct of a User), please send your complaint via Contact Us form. To assist us in dealing with your complaint fully and as quickly as possible, please ensure that any communication you send to us contains as much detail about your complaint as possible. Attaching your account number/subscription ID (where applicable), your full name and address in your complaint, and URL to the issue, will help us to better handle your complaint.
                <br /><br />
                What Happens Next?
                We will aim to respond to send a Final Response to your complaint as quickly as possible; however, depending on the complexity of the issue, it can take up to 7 business days.
                <br /><br />
                1.    We will take such steps as we consider to be appropriate to investigate your complaint within a timescale which is appropriate to the nature of your complaint;
                <br /><br />
                2.    If we require further information or documents from you, we will contact you to let you know;
                <br /><br />
                3.    We will in good faith investigate your complaint within seven (7) business days;
                <br /><br />
                4.    If we are satisfied that the Content is unlawful or non-consensual, we will immediately remove such Content, and we will notify you of our decision by email or other electronic message;
                <br /><br />
                5.    If we are satisfied that the Content is not unlawful or non-consensual, we will notify you of our decision by email or other electronic message.
                <br /><br />
                Who can use this Complaints Policy?
                Whether or not you are a User of 4PlayDate.com, you can use this Complaints Policy to alert us to any complaint which you have relating to 4PlayDate.com.
            
                <br /><br /><br />
                </div>
            </main>
            <Footer />
        </>
     );
}

export default Complaints;
import Header from "../components/header";
import Footer from "../components/footer";

function Terms() {
    return ( 
        <>
            <Header />
            <main>
                <div className="container">
                <br /><br />

                Terms of service

                <br />
                <br />
                1.    Advertisers must be at least 18 years of age or older. Visitors must be at least 18 years of age or older. We are entitled to check your age and, if in doubt, to ask for additional proof (e.g. a copy of an ID). The copy of your ID will be processed in accordance with the Privacy Policy. If we have any doubts about the age, we are also entitled to refuse and/or terminate access to the Website and the Services.
                <br /><br />
                2.    One Account Account may be created per User. Sharing Accounts or registering multiple Accounts is not permitted unless we have given our express prior written (or e-mail) consent.
                <br /><br />
                3.    You guarantee that all (personal) data that you provide in the context of the Service (including but not limited to name, address details, telephone number, date of birth, ID and e-mail address) are complete, correct and up-to-date and that you use the Website and the Service exclusively for yourself. In addition, you guarantee that you will comply with all applicable laws and regulations with regard to the purchase or provision of the erotic / sex services.
                <br /><br />
                4.    By placing Advertisements, (personal) data or other content on the Website and/or within the Service, you give Tease Media permission to publish this data, Advertisements, the Profile and/or other content on the Website. You guarantee that you are authorized to give that permission. You are aware that the positioning of Advertisements and other information on the Website depends on a number of factors, including but not limited to the fee paid, and that no rights can be derived from this.
                <br /><br />
                5.    Consent for Distribution and Upload:
                <br /><br />
                a. The Content Provider agrees to obtain and keep on record written consent from all persons depicted in the content they upload or generate. This consent should be specific and cover the following areas: - Consent to be depicted in the content. - Consent to allow for the public distribution of the content and to upload the content to 4PlayDate.com - If the content will be made available for downloading by other users, consent to have the content downloaded.<br />
                b. The Content Provider shall ensure that they have the necessary legal rights and permissions to distribute and upload the content on 4PlayDate.com. This includes obtaining consent from any third-party rights holders, such as photographers or videographers.
                <br /><br />
                8.    You are responsible and liable for the content of the Advertisements, Profile, (personal) data and other content published by you on the Website. You guarantee that the content thereof is correct, up-to-date and reliable and does not violate the applicable laws and regulations and is not unlawful. In addition, you guarantee that you will not treat other Users unfairly with this data, and that you will not harm the interests and good name of Tease Media.
                <br /><br />
                9.    In any case, the following content may not be placed on the Website:
                <br /><br />
                10. Content that violates any law or regulation or these Terms and Conditions;
                <br /><br />
                11. Content that refers to or relates to any form of legal prostitution, illegal prostitution, including but not limited to forced prostitution, blackmail, threats of violence or otherwise involuntary prostitution and child prostitution, child pornography and any other illegal/criminal conduct, services or products or services or products that may in any way cause harm to third parties;
                <br /><br />
                12. Content that infringes intellectual property rights or privacy rights of third parties or violates other rights of 4playdate.com or third parties;
                <br /><br />
                13. Content containing computer programs (including autofreshers/mouseclickers etc), files and/or other material that contains destructive and incalculable characteristics such as manipulated files, "hidden" files (for example: images, which are integrated into audio files), Worms, Trojan horses or bots for scrolling, or for further activities, which disrupt or may disrupt the integrity or functioning of the Website or of online communication in general;
                <br /><br />
                14. Content that in any way mentions or refers to another website competing or similar to the Website;
                <br /><br />
                We are at all times entitled, at our own discretion, to change, shorten or remove content of Users of the Website, without this in any way leading to any right of the User to compensation. For example, we will do so if the content violates Article 4.
                <br /><br />
                17. Any (personal) data obtained via the Website may only be used for the purpose for which the User received it, namely responding to an Advertisement or a message received via the Website. It is not permitted to collect and process this data for any other purpose, including – but not limited to – any form of unsolicited communication (spam). If you act contrary to the provisions of this article 4, you forfeit, without further warning or notice of default and without judicial intervention being required, an immediately due and payable fine of Dollars 500 (five hundred dollars) per event or per day that the data is used, without prejudice to the right of 4playdate.com to take other measures available to it against you and/or to claim compensation.
                <br /><br />
                18. Appeal Procedure for Content Removal
                <br /><br />
                Submission of Appeal:
                Individuals depicted in content uploaded to Website can submit a written appeal requesting the removal of specific content. You can use this application form (link) The appeal should include the appealing party's contact information, identification of the content, and reasons why consent was not given or is void under applicable law. Investigation and Review:
                We will conduct a prompt investigation into the appeal, reviewing the content and gathering additional information if necessary. Privacy and safety of all parties involved will be considered during the investigation. Determination and Decision:
                Based on the investigation's outcome, we will make a decision regarding content removal if consent was not given or is void under applicable law. The appealing party will be notified of the decision, including the reasons behind it. Disagreement Resolution:
                If the appealing party disagrees with the decision, we can ask for resolution through a neutral body. The appealing party may request a neutral mediator or arbitration to reach a resolution. We will provide relevant information and assistance regarding dispute resolution options.
                <br /><br />
                24. ZERO TOLERANCE POLICY TOWARDS ABUSES
                <br /><br />
                25. If it is suspected that you are acting in violation of article 7, we can immediately terminate the agreement and the use of the Website and the Services without this in any way leading to any right to compensation. We also reserve the right, at our own discretion, to inform the police, the judiciary and other relevant authorities and organizations in this context and to file a report. We will, at our own discretion, cooperate with legally valid requests from the government/judicial authorities to provide data in the context of the investigation of the abuses referred to in Article 7.
                <br /><br />
                26. In the context of our zero tolerance policy regarding abuses in the sex industry, we also call on visitors to report abuses to us and to the relevant authorities.
                <br /><br />
                27. While we prohibit Advertisers under the age of 18 from using our Services and perform age checks, we unfortunately cannot guarantee that an Advertiser is always 18 years of age or older. If you contact an Advertiser, we ask you to check the age of the Advertiser yourself. If you have any doubts as to whether the Advertiser is a parent over the age of 18, or find that the Advertiser is a minor, you must immediately refrain from any further contact with the Advertiser in question and immediately inform us and/or the police and judicial authorities of your doubt or observation. If you wish, you can do this on an anonymous basis via the Report Crime Anonymous hotline.
                <br /><br />
                28. If you suspect or have reasonable grounds to believe that an Advertiser is offering its (erotic) services under duress, blackmail, threats of violence or otherwise involuntarily, you must immediately refrain from any further contact with the Advertiser and immediately inform us and / or the police and judicial authorities about this. If you wish, you can do this on an anonymous basis via the Report Crime Anonymous hotline.
                <br /><br />
                29. If you believe that certain information on the Website is unlawful, you can also report this to us. We will process the report, provided that it meets all the requirements set by us for this purpose. If the report shows that the offending material is clearly unlawful, we will remove it;
                <br /><br /><br />
                </div>
            </main>
            <Footer />
        </>
     );
}

export default Terms;
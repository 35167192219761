import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Main from "./pages/Main";
import Model from "./pages/Model";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import Complaints from "./pages/Complaints";
import Cancelation from "./pages/Cancelation";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/model/:id" element={<Model />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/policy" element={<Policy />}></Route>
          <Route path="/complaints" element={<Complaints />}></Route>
          <Route path="/cancelation" element={<Cancelation />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

function Footer() {
    return ( 
        <>

            <footer>
                <div className="container">
                    <div className="footer-row">
                        <div className="copyright">
                            Copyright © 4PlayDate 2024 
                        </div>
                        <div className="links"> 
                            <a href="/terms" target="_blank" className="">Terms of Service</a>
                            <a href="/policy" target="_blank" className="">Privacy Policy</a>
                            <a href="/complaints" target="_blank" className="">Complaints Policy</a>
                            <a href="/cancelation" target="_blank" className="">Cancelation Policy</a>
                        </div>
                    </div>
                </div>
            </footer>
        
        </>
     );
}

export default Footer;
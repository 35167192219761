import Footer from "../components/footer";
import Header from "../components/header";
import storage from '../storage/models.json';
import { Link } from "react-router-dom";

function Main() {

    let models = storage.models.map((val, ind, arr) => {

        let imagePath = require(`../assets/models/${val.image}`);

        let path = "/model/" + val.id;

        return(
            <div className="item">
                <div className="avatar">
                    <Link to={path}>
                        <img src={imagePath} alt={val.name} />
                    </Link>
                </div>
                <div className="info">
                    <h2>{val.name}</h2>
                    <h3>📍 {val.location}</h3>
                    <h3 className="phone">☎️ &nbsp; {val.phone}</h3>

                    <Link to={path}>
                        <div className="main-button">
                            Zum Profil
                        </div>
                    </Link>
                </div>
                <div className="socials">
                    <a href={val.whatsapp} target="_blank">
                        <div className="whatsapp">
                            <i class="bi bi-whatsapp"></i>
                        </div>
                    </a>
                    <a href="#">
                        <div className="telegram">
                            <i class="bi bi-telegram"></i>
                        </div>
                    </a>
                </div>
            </div>
        );
    });

    return ( 
        <>
            <Header />

            <main>
                <h1>Mädchen</h1>
                <div className="container">
                    <div className="items">
                        { models }
                    </div>
                </div>
            </main>

            <Footer />
        </>
     );
}

export default Main;
import Header from "../components/header";
import Footer from "../components/footer";

function Cancelation() {
    return ( 
        <>
            <Header />
            <main>
                <div className="container">
                <br /><br />

                
                Cancelation policy
                <br /><br />
                If you would like to cancel your subscription from billing, please reach out to us via Contact Us form.


                <br /><br /><br />
                </div>
            </main>
            <Footer />
        </>
     );
}

export default Cancelation;
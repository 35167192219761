import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

function Header() {
    return ( 
        <>
            <header className="">
                <nav class="container navbar navbar-light">

                    <a class="navbar-brand" href="#">
                        <Link to="../">
                            <img src={logo} alt="" />
                        </Link>
                    </a>

                    <div className="languages">
                        <a href="#">🇩🇪 DE</a>
                    </div>
                </nav>
            </header>
        </>
     );
}

export default Header;
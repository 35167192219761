import { useParams, useNavigate } from 'react-router-dom';
import storage from '../storage/models.json';
import { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link } from "react-router-dom";

function Model() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [model, setModel] = useState(null);  // Use null as initial state

    useEffect(() => {
        const foundModel = storage.models.find(obj => obj.id === parseInt(id));

        if (foundModel) {
            setModel(foundModel);
        } else {
            navigate("/");
        }
    }, [id, navigate]);

    // If model or model.image is not defined, use a fallback image
    const imagePath = model && model.image ? require(`../assets/models/${model.image}`) : "default-placeholder.jpg";

    if (!model) {
        return <div>Loading...</div>; // Show a loading state while the model is being fetched
    }

    return (
        <>
            <Header />

            <main className='model'>
                <div className="container">
                    <div className="back-link">
                        <Link to="../">
                            &lt; Zurück
                        </Link>
                    </div>
                    <div className="model-card">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="img-container">
                                    <img src={imagePath} alt={model.name || "Model"} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="info">
                                    <h2>{model.name}</h2>
                                    <h3>📍 {model.location}</h3>
                                    <h3>☎️ &nbsp; {model.phone}</h3>
                                    <p>{model.description}</p>

                                    <div className="buttons">
                                        <a href={model.whatsapp} target='_blank'><div className="button-whatsapp">Whatsapp</div></a>
                                        <a href="#"><div className="button-telegram">Telegram</div></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
}

export default Model;
